.support-us{
    color: #000000;
    font-size: 1.2rem;
    color: #fcf2f2;
    &__importance-of-support{
        padding: 20px;
        margin: 10px 0;
        border: 1px solid #ddd;
        border-radius: 8px;
        height: auto;
        width: 95%;
    
        @media (max-width: 768px) {
            height: auto;
        }
    }
}
.hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c0505;
    background: linear-gradient(90deg, rgb(13, 14, 15) 0%, rgb(212, 45, 30) 60%, rgb(206, 27, 14) 100%);
 
margin-top: 20px;
    text-align: center;
    padding: 50px 20px;
    background-position:top;
    
    img {
      width: 100%;
      height: 50vh;
        object-fit: cover;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      // filter: sepia(1) contrast(1.2) brightness(0.9);
      // opacity: 0.4;
    }
  
    h1 {
        position: absolute;
        left: 0;
        color: #ffd900a5; /* Gold color */
        font-size: 4rem;
        font-weight: bold;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
        font-family: 'Papyrus', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Ancient look-alike font */
        letter-spacing: 2px; /* Add some spacing between letters */
        border: 2px solid #543c2b; /* Add a brown border to the text */
        padding: 10px 20px;
        background: rgba(0, 0, 0, 0.5);
    }
  }
  
.about-component {
  padding: 20px;
  border-radius: 5px;
  p{
    font-size: 1.2rem;
    color: #fcf2f2;

    
    font-weight:500;
  }
}
.site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; // Ensures the header stays on top of other elements
  background-color: #fff; // Ensure background color matches the header's background
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional: adds a subtle shadow for better visibility
  width: 100%;
}

.site-content {
  margin-top: 70px; // Adjust this value to the height of your fixed header
  width: 100%;
}

// .site-footer {
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   z-index: 1000; // Ensures the footer stays on top of other elements
// }
/* Example CSS Colors */
:root {
  --sand-light: #f4e1c1; /* Light sand color */
  --sand-medium: #e0c9a6; /* Medium sand color */
  --sand-dark: #b69a79; /* Darker sand color */
  --dune-highlight: #f0dac3; /* Highlight color for dunes */
  --dune-shadow: #c9a78b; /* Shadow color for dunes */
  --text-color: #3c2f2f; /* Dark text color for contrast */
  --accent-color: #af866b; /* Accent color for buttons and links */
}

body {
  background-color: var(--sand-light);
  // background-color: black;
  color: var(--text-color);
  font-family: "Arial", sans-serif;
}


main {
  background-color: var(--sand-medium);
 
}

a {
  color: var(--accent-color);
}


@import "../../Styles/colors";
@import "../../Styles/mixins";
@import "../../Styles/variables";

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  // background-color: #333;

  .logo h3 {
    margin: 0;
    color: #fff;
    
  }

  .nav-toggle {
    display: none;
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .nav-links {
    display: flex;
    align-items: center;

    a {
      color: rgb(0, 0, 0);
      text-decoration: none;
      margin-left: 1rem;
      padding: 1rem;

      font-weight: bold;
      border-radius: 5px;
      &:hover {
       background-color: #fff;
      }
    }

    &.show {
      display: block;
    }
  }

  .social-media-icons {
    display: flex;
    align-items: center;
    margin-left: auto;

  }
}

@media (max-width: 768px) {
  .nav {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    width: 100%;
.logo{
    width: 100%;
    text-align: center;
    padding: 1rem 0;
}
    .nav-toggle {
      display: block;
      margin: 1rem;
      align-self: center;
    }

    .nav-links {
      display: none;
      width: 100%;

      a {
        margin: 0.5rem 0;
        width: 100%;
        text-align: center;

      }
    }

    .nav.open .nav-links {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      padding-bottom: 1rem;
      margin-right: 1rem;
      padding-right: 1rem;
    }
  }
}
.link{
  color: #fff;
  text-decoration: none;
  margin-left: 1rem;
  padding: 1rem;
  font-weight: bold;
  border-radius: 5px;
  width: 60px;
  &:hover {
    background-color: #fff;
  }
}
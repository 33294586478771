
.footer{
  background: linear-gradient(90deg, rgb(13, 14, 15) 0%, rgb(212, 45, 30) 50%, rgb(226, 126, 72) 100%);
  padding: 10px 20px; // Adjust padding as needed
    z-index: 100;
  width: 100%;
  margin: 0;
    .footer_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      p{
        color: #ffffff;
      }
    }
  

  }
  
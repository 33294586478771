.homepage {
  margin: 0;
  width: 100%;
  background-color: black;
  background-image: url('../../Assets/Images/dunes2.webp');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  section {
    position: relative; 
    padding: 20px;
    margin: 10px 0;
    // border: 1px solid #ddd;
    border-radius: 8px;
    min-height: 50vh;
    height: auto;

    @media (max-width: 768px) {
      height: auto;
    }

    // &.home {
    //   // background-color: #f9f9f9;
    // }

    &.about, &.gallery, &.contact, &.support {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        // opacity: 0.3; 
        z-index: 1;
        border-radius: 8px;
      }
      background-color: rgba(0, 0, 0, 0.7); 
     
    }
&.contact{
  width: 100%;
  background-color: rgba(0, 0, 0, 0); 

}
    &.shows {
      background-color: #e9e9e9;
    }

    &.tickets {
      background-color: #e0e0e0;
    }

    &.news {
      background-color: #d0d0d0;
    }

    h2 {
      margin-top: 0;
      position: relative; 
      z-index: 2;
    }

  
    > * {
      position: relative;
      z-index: 2;
    }
    .text-content {
      background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
      padding: 10px;
      border-radius: 5px;
    }
  }
}

@import "../../Styles/colors";
@import "../../Styles/mixins";
@import "../../Styles/variables";

.header {
  background-color: #121416; 
  padding: 10px 20px; 
  background-image: url('../../Assets/Images/dunes2.webp');
  
  background: linear-gradient(90deg, rgb(72, 188, 226) 0%, rgb(230, 191, 141) 30%, rgb(72, 188, 226) 100%);
  background: linear-gradient(90deg, rgb(13, 14, 15) 0%, rgb(147, 24, 13) 50%, rgb(181, 82, 28) 100%);

  @include mobile {
    padding: 10px 0px;
  }

  .header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background: rgb(2,0,36);
    // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(66,6,6,1) 14%, rgba(0,212,255,1) 100%);
    @include mobile {
     padding: 0;
     margin: 0;
    }
  }

  .header_logo {
    width: 80px; 
    height: auto; 
  }
  h3{
    color: #ffffff;
    margin-left: 1rem;
  }
  &_title a{
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 5rem;
    @include tablet {
      margin-left: 1rem;
    }
    @include mobile {
      margin-left: 1rem;
    }
  }
  &_menu{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5rem;
    @include tablet {
      margin-right: 1rem;
    }
    @include mobile {
      margin-left: 1rem;
      padding: 0%;
    }
  }
}
